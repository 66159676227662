import { lazy } from 'react';

// Lazy load all components
const components = {
    // Main components
    Home: lazy(() => import('../pages/Home')),
    AboutUs: lazy(() => import('../pages/AboutUs')),
    Curriculum: lazy(() => import('../pages/Curriculum')),
    AiTools: lazy(() => import('../pages/AiTools')),
    Blog: lazy(() => import('../pages/Blog')),
    Contact: lazy(() => import('../pages/Contact')),

    // Tool components
    HeroCreator: lazy(() => import('../pages/tools/HeroCreator')),
    HeroPresentation: lazy(() => import('../pages/tools/HeroCreatorPresentation')),
    MonsterCreator: lazy(() => import('../pages/tools/MonsterCreator')),
    MonsterPresentation: lazy(() => import('../pages/tools/MonsterCreatorPresentation')),
    WorldCreator: lazy(() => import('../pages/tools/WorldCreator')),
    WorldMapPresentation: lazy(() => import('../pages/tools/WorldMapCreatorPresentation')),
    WorldMapDesignPresentation: lazy(() => import('../pages/tools/WorldMapDesignPresentation')),
    StoryCreator: lazy(() => import('../pages/tools/StoryCreator')),
    StoryPresentation: lazy(() => import('../pages/tools/StoryCreatorPresentation')),
    CareerQuest: lazy(() => import('../pages/tools/CareerQuest'))
};

export const ROUTES = {
    // Main Routes with components
    MAIN: [
        { path: '/', component: components.Home },
        { path: '/about', component: components.AboutUs },
        { path: '/curriculum', component: components.Curriculum },
        { path: '/ai-tools', component: components.AiTools },
        { path: '/blog', component: components.Blog },
        { path: '/contact', component: components.Contact }
    ],

    // Legal Routes with components
    LEGAL: [
        { path: '/terms', component: components.Home },
        { path: '/privacy', component: components.Home },
        { path: '/cookies', component: components.Home }
    ],

    // Tool Routes with components
    TOOLS: {
        ROOT: '/tools',
        ROUTES: [
            { path: 'hero-maker', component: components.HeroCreator },
            { path: 'hero-presentation', component: components.HeroPresentation },
            { path: 'monster-maker', component: components.MonsterCreator },
            { path: 'monster-presentation', component: components.MonsterPresentation },
            { path: 'world-maker', component: components.WorldCreator },
            { path: 'world-map-presentation', component: components.WorldMapPresentation },
            { path: 'world-map-design', component: components.WorldMapDesignPresentation },
            { path: 'story-maker', component: components.StoryCreator },
            { path: 'story-presentation', component: components.StoryPresentation },
            { path: 'career-quest', component: components.CareerQuest }
        ]
    }
};

// Export path constants for use in links
export const PATHS = {
    HOME: '/',
    ABOUT: '/about',
    CURRICULUM: '/curriculum',
    AI_TOOLS: '/ai-tools',
    BLOG: '/blog',
    CONTACT: '/contact',
    LEGAL: {
        TERMS: '/terms',
        PRIVACY: '/privacy',
        COOKIES: '/cookies'
    },
    TOOLS: {
        ROOT: '/tools',
        HERO_MAKER: '/tools/hero-maker',
        HERO_PRESENTATION: '/tools/hero-presentation',
        MONSTER_MAKER: '/tools/monster-maker',
        MONSTER_PRESENTATION: '/tools/monster-presentation',
        WORLD_MAKER: '/tools/world-maker',
        WORLD_MAP_PRESENTATION: '/tools/world-map-presentation',
        WORLD_MAP_DESIGN: '/tools/world-map-design',
        STORY_MAKER: '/tools/story-maker',
        STORY_PRESENTATION: '/tools/story-presentation',
        CAREER_QUEST: '/tools/career-quest'
    }
};
