import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ROUTES } from './routes/config';

// Styles
import './styles/colors.css';

function App() {
    const HomeComponent = ROUTES.MAIN[0].component;

    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <div>
                    <Routes>
                        {/* Main Routes */}
                        {ROUTES.MAIN.map(({ path, component: Component }) => (
                            <Route
                                key={path}
                                path={path}
                                element={<Component />}
                            />
                        ))}

                        {/* Tools Routes */}
                        <Route path={ROUTES.TOOLS.ROOT}>
                            <Route index element={<HomeComponent />} />
                            {ROUTES.TOOLS.ROUTES.map(({ path, component: Component }) => (
                                <Route
                                    key={path}
                                    path={path}
                                    element={<Component />}
                                />
                            ))}
                        </Route>

                        {/* Catch all route */}
                        <Route path="*" element={<HomeComponent />} />
                    </Routes>
                </div>
            </Suspense>
        </Router>
    );
}

export default App;
